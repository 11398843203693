.messenger {
  height: calc(100vh-70px);
  display: flex;
}

.chatMenuWrapper,
.chatBoxWrapper {
  margin: 20px;
  font-weight: 450;
  font-size: 20px;
}
.profilelist {
  padding-left: 15px;
}
.createicon {
  margin-top: 25px;
  font-size: 15px;
  margin-left: 160px;
}
.verticalline {
  border-right: 2px solid gray;
  height: 500px;
}
.conversation {
  display: flex;
  padding: 10px;
}
.messages {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.messageTop {
  display: flex;
}
.messageText {
  padding: 10px;
  border-radius: 20px;
  background-color: #1877f2;
  color: white;
  max-width: 300px;
}
.chatuserprofile {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.chatBoxBottom {
  -webkit-box-shadow: 0px 8px 32px rgba(39, 39, 39, 0.24);
  box-shadow: 0px 8px 32px rgba(39, 39, 39, 0.24);
  width: 73%;
  margin-left: 2rem;
  border-radius: 32px;
  display: flex;
  align-items: center;
  height: 3rem;
  overflow: hidden;
  background-color: #fff;
  position: fixed;
  bottom: 0;
}
.sendbutton {
  background: #fff;
  border: none;
  cursor: pointer;
  text-align: center;
  margin-left: auto;
  padding-right: 0.7rem;
  transition: all 0.3s ease;
}
.sendbutton:hover {
  scale: 1.1;
}
.sendbutton:active {
  scale: 0.8;
}
.sendbutton:disabled {
  pointer-events: none;
}
.chattextinput {
  margin-left: 50px;
  margin-top: 5px;
  width: 90%;
  text-decoration-line: none;
  border: none;
  outline: none;
}
.chatboxbottomimg {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-left: 10px;
  margin-top: 6px;
  position: absolute;
  z-index: 1000;
}
.messageBottom {
  font-size: 12px;
  margin-top: 10px;
}
.messageImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.conversationImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}
.conversationName {
  padding-top: 5px;
  font-weight: 400;
}
.chatMenuInput {
  width: 90%;
  padding: 10px 0;
  border: 1 px solid gray;
}

/* search input CSS */
.search_input {
  font-weight: 600;
}

.messages {
  height: 82%;
}

.isTyping {
  height: 78%;
}

/* media query */
@media screen and (max-width: 1650px) {
  .chatBoxBottom {
    width: 70vw;
  }
}

@media screen and (max-width: 1450px) {
  .chatBoxBottom {
    width: 67vw;
  }
}

@media screen and (max-width: 1250px) {
  .chatBoxBottom {
    width: 62vw;
  }
}

@media screen and (max-width: 1000px) {
  .chatBoxBottom {
    width: 56vw;
  }
}

@media screen and (max-height: 1000px) {
  .messages {
    height: 78%;
    margin-top: 0;
  }
  .isTyping {
    height: 76%;
  }
}

@media screen and (max-height: 850px) {
  .messages {
    height: 75%;
  }

  .isTyping {
    height: 73%;
  }
}

@media screen and (max-height: 750px) {
  .messages {
    height: 73%;
  }

  .isTyping {
    height: 68%;
  }
}

@media screen and (max-height: 650px) {
  .messages {
    height: 68%;
  }

  .isTyping {
    height: 64%;
  }
}

@media screen and (max-height: 600px) {
  .messages {
    height: 62%;
  }

  .isTyping {
    height: 54%;
  }
}
