:root {
  --size: 6.25rem;
}

#local-screen {
  width: 100%;
  height: auto;
  border-radius: 15px;

  height: calc(6.25 * var(--size));
  /*for testing use gray*/
  background-color: black;

  overflow: hidden;
  position: relative;
}

#time-dot {
  margin: 0 15px;
}

#time-dot .MuiBadge-dot {
  background-color: #d3d3d3;
}

/****inroom******/
#inroom {
  width: 60%;
  position: relative;
}

#video-divider {
  margin: 20px 0 !important;
  height: 0.5px !important;
}

#your-video-feed {
  position: relative;
  width: 100%;
}

#your-video-feed .participant-list {
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  overflow-y: scroll;
  height: -webkit-fill-available;
  z-index: 100;
}

.your-video-tool {
  margin: 15px 0;
}

.your-video-tool .your-video-tool-button {
  padding: 11px !important;
  border-radius: 15px !important;
  margin: 0 10px;
  min-width: 30px;
}

#leave-button {
  background-color: #ff5e47;
  color: white;
  border-radius: 15px;
  width: 10rem;
  font-size: large;
  padding: 5px 15px !important;
  margin-right: 7px;
}

/***skeleton***/
.skeleton {
  width: var(--size) !important;
  height: var(--size) !important;
  border-radius: 8px !important;
}

/*******ParticipantVideoCard********/
#participant-video-item {
  flex-direction: column;
}

#participant-video-item .participant-video-card {
  width: fit-content;
  border: 4px solid #fff;
  border-radius: 20px !important;
}
#participant-video-feed {
  width: var(--size);
  height: var(--size);

  /*use gray for testing*/
  background-color: black;
  position: relative;
  border-radius: 20px 0px 20px 20px;

  overflow: hidden;
}

#participant-video-feed video {
  width: 130%;
}

#participant-video-item span {
  background-color: white;
  width: 110px;
  text-align: center;
  border-radius: 6px;
  position: relative;
  bottom: 8px;
  overflow: hidden;
  color: #262556;
  font-weight: 500;
}

/*******dropdown menu**********/
#kebab-menu {
  position: absolute;
  right: -3px;
  top: 0;
  background-color: white;
  border: white;
  border-radius: 4px;
  color: gray;
  font-size: 1rem;
  padding: 2px 4px !important;
}

#simple-menu .MuiList-padding {
  padding: 0;
}

#simple-menu .MuiPaper-rounded {
  border-radius: 8px;
  max-height: fit-content !important;
}

#simple-menu .MuiMenuItem-root {
  width: 17.9rem;
  padding: 0.8rem !important;
  color: black;
  display: flex;
  align-items: center;
}

#ice-breaker {
  background-color: rgb(255, 250, 221);
  padding: 1rem 1rem;
  border: 5px solid rgb(255, 203, 0);
  border-radius: 18px;
  font-size: 1.2rem;
  color: rgb(45, 46, 95);
  display: inline-block;
  width: 55%;
  font-weight: 500;
  word-break: break-word;
  position: absolute;
  top: 8%;
  right: 24%;
  z-index: 100;
}

/*****menuList********/
#simple-menu .menulist-icon {
  margin-right: 8px;
}

@media only screen and (min-width: 500px) and (max-width: 1101px) {
  #inroom {
    width: 95%;
    position: relative;
  }
  :root {
    --size: 5rem;
  }
}

@media only screen and (max-width: 500px) {
  #inroom {
    width: 95%;
    position: relative;
  }

  :root {
    --size: 4.5rem;
  }

  #leave-button {
    width: 100%;
  }

  .your-video-tool .your-video-tool-button {
    width: 20%;
  }

  #participant-video-item {
    padding-right: 8px;
  }

  #participant-video-item span {
    font-size: 0.8rem;
  }

  #participant-video-item .participant-video-card {
    border: 3px solid #fff;
  }

  .your-video-tool {
    align-items: center;
  }
}
